"use client";
import { createMedia } from "@artsy/fresnel";

const breakpoints = {
  sm: 0,
  md: 768,
  lg: 1024,
  xl: 1280,
};

const AppMedia = createMedia({
  breakpoints,
});

// Generate CSS to be injected into the head
export const mediaStyle = AppMedia.createMediaStyle();
export const { Media: MediaQuery, MediaContextProvider } = AppMedia;
export default MediaContextProvider;

type BreakpointKey = keyof typeof breakpoints;

/**
 * Returnerer en media query-streng for et gitt brytpunkt.
 * @param breakpoint - 'sm' | 'md' | 'lg' | 'xl'
 * @param type - 'min' or 'max' width (default: 'min')
 */
export function getMediaQuery(breakpoint: BreakpointKey, type: "min" | "max" = "min"): string {
  const value = breakpoints[breakpoint];
  return `(${type}-width: ${value}px)`;
}

export const lockBodyWhenModalIsOpen = (modalIsOpen: boolean) => {
  if (modalIsOpen) {
    const scrollY = window.scrollY;

    document.body.style.position = "fixed";
    document.body.style.top = `-${scrollY}px`;

    window.addEventListener("scroll", () => {
      document.documentElement.style.setProperty("--scroll-y", `${scrollY}px`);
    });
  } else {
    const scrollY = document.body.style.top.replace("px", "");

    document.body.style.position = "";
    document.body.style.top = "";
    window.scrollTo(0, Number.parseInt(scrollY || "0") * -1);

    window.removeEventListener("scroll", () => {
      document.documentElement.style.setProperty("--scroll-y", `${scrollY}px`);
    });
  }
};
